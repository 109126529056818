@import '@ingka/base/style';
@import '@ingka/variables/style';
@import '@ingka/typography/style';

// Variables used exclusively for this component
$box-shadow-input-popover: 1px 2px 4px 0 #{$colour-neutral-3};
$border-input-popover: #{$thickness-thin} solid #{$colour-neutral-3};
$layer-overlay: 300;
$layer-overlay-content: 310;

.datePicker {
  .disabled {
    color: $colour-neutral-2;
  }

  .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 0.125rem 0;
  }

  input {
    min-width: 7rem;
    padding-right: 24px;
    width: 100%;

    &.clearable {
      min-width: 8rem;
      padding-right: 48px;
    }
  }

  .react-datepicker {
    background-color: $colour-neutral-1;
    box-shadow: $box-shadow-input-popover;
    border: $border-input-popover;
    border-radius: $radius-l;
    font-family: inherit;
    padding: 16px;
    z-index: $layer-overlay-content;
  }

  .react-datepicker__input-container {
    position: static;
  }

  .react-datepicker-popper {
    z-index: $layer-overlay;
  }

  .react-datepicker__close-icon {
    right: 24px;
    bottom: 4px;
    top: unset;
    height: unset;
    padding: 4px;

    &::after {
      background-color: transparent;
      color: currentColor;
      font-size: 1rem;
      font-weight: bold;
      height: 32px;
      width: 32px;
    }

    &:hover::after {
      background: $colour-neutral-2;
    }
  }

  .react-datepicker__day {
    border-radius: 50%;
  }

  .react-datepicker__day--disabled {
    text-decoration: line-through;
  }

  .react-datepicker__day--selected {
    background-color: $colour-static-ikea-brand-blue;
    border-radius: 50%;
    color: $colour-neutral-1;
    font-weight: bold;
    text-decoration: none;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: $colour-neutral-2;
  }

  .react-datepicker__navigation {
    border-radius: 50%;
    top: 28px;
  }

  .react-datepicker__navigation--next {
    right: 24px;
  }

  .react-datepicker__navigation--previous {
    left: 24px;
  }

  .react-datepicker__header__dropdown {
    margin-top: $letter-spacing-50;
  }

  .react-datepicker__header,
  .react-datepicker__today-button {
    background-color: inherit;
    border: 0;
    margin: $letter-spacing-50;
  }

  .react-datepicker__navigation:hover {
    background-color: $colour-neutral-2;
  }

  .react-datepicker__day:hover {
    background-color: $colour-neutral-2;
  }

  .react-datepicker__today-button:hover {
    background-color: $colour-neutral-2;
    border-radius: 64px;
  }

  .react-datepicker__navigation-icon::before {
    border-color: $colour-neutral-7;
  }

  .react-datepicker__navigation-icon--next::before {
    left: unset;
    transform: rotate(45deg) translateX(11%) translateY(68%);
  }

  .react-datepicker__navigation-icon--previous::before {
    right: unset;
    transform: rotate(225deg) translateX(-11%) translateY(-68%);
  }

  .react-datepicker__navigation-icon:hover::before {
    border-color: $colour-neutral-7;
  }

  &.focus .input-field__border {
    border-width: 2px;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 6px;

    .react-datepicker__triangle {
      &::after,
      &::before {
        border-bottom-color: $colour-static-ikea-brand-blue;
      }
    }
  }

  &.focus:not(.input-field--error, .input-field--success) .input-field__border {
    border-color: $colour-static-ikea-brand-blue;
  }
}
