/** 
CSS animations for the animated customer comment card transitions.
*/
@keyframes whoosh {
  0% {
    opacity: 1;
    transform: translateY(0) translateX(0) scale(1);
  }

  50% {
    opacity: 0.7;
    transform: translateY(-30px) translateX(10px) scale(1.05);
  }

  100% {
    opacity: 0;
    transform: translateY(-200px) translateX(30px) scale(0.8);
  }
}

.forwardAnimation .resultCard {
  animation:
    fadeIn 0.8s ease,
    whoosh 1.5s ease forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.dismissAnimation .resultCard {
  animation:
    fadeIn 0.8s ease,
    fadeOut 1.5s ease forwards;
}
