/**
* 6.x → 7.x migration guide https://mantine.dev/guides/6x-to-7x/#createstyles-and-global-component
* With new frequent usage of Mantine components like the Accordion and the RTF editor,
* it feels more appropriate to create a separate separate file to handle custom styles.
* Since we are moving away from Styled components and won't be using Mantine's recommended CSS module approach,
* handling data-* attributes and nested selectors can get pretty tricky in inline css in js styles
* So using the 'className' approach seems to be the easiest way to go.
*/

$colourBrandBlue: #0058a3;
$colourBrandDarkBlue: #003f73;
$colourNeutralGrey100: rgba(245, 245, 245, 1);
$colourNeutralGrey700: rgba(72, 72, 72, 1);
$colourBorderLight: rgba(223, 223, 223, 1);
$colourBackdropLight: rgba(0, 0, 0, 0.15);
$boxShadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

/** Remove default padding added by Mantine */
.accordion-coaching-sessions,
.accordion-split-missions {
  .mantine-Accordion-content {
    padding: 0;
  }
}

/** Overwrite the default Mantine Stepper styles */
.mantine-Stepper-root {
  background-color: transparent;
  margin: 0;

  .mantine-Stepper-steps {
    .mantine-Stepper-stepIcon {
      border-color: transparent;

      &[data-progress] {
        color: $colourNeutralGrey100;
        background-color: $colourBrandBlue;
        border-color: transparent;
        box-shadow: $boxShadow;
      }

      &[data-completed] {
        color: $colourBorderLight;
        border-color: transparent;
        background-color: $colourBorderLight;
        opacity: 0.85;
      }
    }

    .mantine-Stepper-stepBody {
      margin-top: 2px;
      color: $colourNeutralGrey700;
      text-align: 'center';
      margin-left: 0;
    }

    .mantine-Stepper-separator {
      background-color: $colourNeutralGrey100;
      vertical-align: middle;
      width: 2px;

      &[data-active] {
        background-color: $colourBrandBlue;
      }
    }
  }
}

/** Overwrite the default Mantine RichTextEditor styles */
.coaching-pointer-editor,
.coworker-note-editor,
.session-comment-editor {
  border-color: transparent;

  :where(ul, ol):not([data-type='taskList']) {
    padding-inline-start: 1.5rem;
  }

  .coaching-pointers-task-items {
    padding-inline-start: 1rem;

    :where(input):checked {
      color: white;
      background-color: $colourBrandBlue;
      border-color: $colourBrandBlue;
    }
  }

  .mantine-RichTextEditor-control {
    background-color: $colourNeutralGrey100;
    color: darken($colourNeutralGrey700, 10%);

    &[data-disabled] {
      cursor: not-allowed;
      user-select: none;
      color: black;
    }

    &[data-active] {
      background-color: $colourBrandBlue;
      border: 1px solid $colourBrandBlue;
      color: white;
      border: transparent;
    }
  }

  a {
    color: $colourBrandBlue;
    font-weight: 500;
    cursor: pointer;
  }
}

.coworker-note-editor,
.session-comment-editor {
  .mantine-RichTextEditor-toolbar {
    padding-inline-start: 0;
  }
}

.coaching-pointer-editor {
  .mantine-RichTextEditor-toolbar {
    padding: 1rem 0.5rem;
  }
}

/**  Mantine Editor link component */
.mantine-RichTextEditor-linkEditor {
  .mantine-RichTextEditor-linkEditorInput {
    box-shadow: $boxShadow;
    border: 2px solid $colourBrandBlue;
    border-radius: 4px;
    width: 20rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
  }

  .mantine-RichTextEditor-linkEditorSave {
    background-color: $colourBrandBlue;
    color: white;
    border: none;
    border-radius: 4px;
    position: absolute;
    right: 0;
  }
}

/** Rendered HTML content/output */
.json-to-html-output {
  h3,
  h4,
  h5,
  p,
  li,
  input {
    margin-bottom: 0.25rem;
  }

  ul,
  ol {
    padding-inline-start: 1.5rem; // Increased indenting for list items
    width: auto;
  }

  // Checkboxes in the rendered HTML content
  ul[data-type='taskList'] {
    list-style: none;

    p {
      display: inline-flex;
      margin-inline-start: 0.5rem;
    }
  }

  a {
    color: $colourBrandBlue;
    font-weight: 500;
    letter-spacing: -0.25px;
    cursor: pointer;
    margin-inline-start: initial;
  }
}
