.embeded-report-container {
  height: 90vh;
  width: 100%;
}

iframe {
  border: none;
}

.mock-link {
  color: cornflowerblue;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
